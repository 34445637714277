import React from "react";

const SignUp = () => {
  return (
    <div>
      <h1>SignUp Page</h1>
      <p>This is some text for the admin Signup</p>
    </div>
  );
};

export default SignUp;

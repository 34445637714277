import React from "react";

const SignIn = () => {
  return (
    <div>
      <h1>Signin Page</h1>
      <p>This is some text for the signin page</p>
    </div>
  );
};

export default SignIn
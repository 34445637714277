import React, { useState } from "react";
import "../../css/App.css";
import { cupData } from "../../types/types";
import CupTriangle from "../CupTriangle";
import VideoCall from "../Shared/VideoCall";

function Player() {
  let [hits, updateHits] = useState<cupData>({
    row: {
      1: {
        column: {
          1: false,
        },
      },
      2: {
        column: {
          1: false,
          2: false,
        },
      },
      3: {
        column: {
          1: false,
          2: false,
          3: false,
        },
      },
      4: {
        column: {
          1: false,
          2: false,
          3: false,
          4: false,
        },
      },
    },
  });

  return (
    <div className="App">
      {/* <header className="App-header">
        <h1>Beerpong</h1>
      </header> */}
      <div className="App-body">
        <VideoCall name="Hannes"/>
        <div>
          <CupTriangle cupHits={hits} updateHits={updateHits} />
          <CupTriangle cupHits={hits} updateHits={updateHits} />

        </div>
      </div>
      <h1>Your Group</h1>
      <ul>
        <li>ALD IPA Ultras vs. SomeRandom</li>
        <li>noch mehr text</li>
        
      </ul>
    </div>
  );
}

export default Player;

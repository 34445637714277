import React, { useState } from "react";
import { cupData } from "../../types/types";
import CupTriangle from "../CupTriangle";

const SecondScreen = () => {
  let [ownTeam, updateOwnTeam] = useState<cupData>({
    row: {
      1: {
        column: {
          1: false,
        },
      },
      2: {
        column: {
          1: false,
          2: false,
        },
      },
      3: {
        column: {
          1: false,
          2: false,
          3: false,
        },
      },
      4: {
        column: {
          1: false,
          2: false,
          3: false,
          4: false,
        },
      },
    },
  });

  let [opponentTeam, updateOpponentTeam] = useState<cupData>({
    row: {
      1: {
        column: {
          1: false,
        },
      },
      2: {
        column: {
          1: false,
          2: false,
        },
      },
      3: {
        column: {
          1: false,
          2: false,
          3: false,
        },
      },
      4: {
        column: {
          1: false,
          2: false,
          3: false,
          4: false,
        },
      },
    },
  });

  return (
    <div>
      <h1>Beate-U Pong</h1>
      <div>
        <h2>Becher Buben (You)</h2>
        <CupTriangle cupHits={ownTeam} updateHits={updateOwnTeam} />
      </div>
      <div>
        <h2>Inner Circle (opponent)</h2>
        <CupTriangle cupHits={opponentTeam} updateHits={updateOpponentTeam} />
      </div>
    </div>
  );
};

export default SecondScreen;

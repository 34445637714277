import React from "react";
import { cupData } from "../../types/types";
import "../../css/cupTriangle.css";

const CupTriangle = (props: { cupHits: cupData; updateHits: any }) => {
  const cupHit = (rowtmp: 1 | 2 | 3 | 4, columntmp: 1 | 2 | 3 | 4) => {
    props.updateHits((currentHits: cupData) => ({
      ...currentHits,
      row: {
        ...currentHits.row,
        [rowtmp]: {
          ...currentHits.row[rowtmp],
          column: {
            ...currentHits.row[rowtmp].column,
            [columntmp]: true,
          },
        },
      },
    }));
  };
  return (
    <div className="triangle-wrapper">
      <div>
        <span
          className={"dot" + (props.cupHits.row[1].column[1] ? " hitted" : "")}
          onClick={() => cupHit(1, 1)}
        />
      </div>
      <div>
        <span
          className={"dot" + (props.cupHits.row[2].column[1] ? " hitted" : "")}
          onClick={() => cupHit(2, 1)}
        />
        <span
          className={"dot" + (props.cupHits.row[2].column[2] ? " hitted" : "")}
          onClick={() => cupHit(2, 2)}
        />
      </div>
      <div>
        <span
          className={"dot" + (props.cupHits.row[3].column[1] ? " hitted" : "")}
          onClick={() => cupHit(3, 1)}
        />
        <span
          className={"dot" + (props.cupHits.row[3].column[2] ? " hitted" : "")}
          onClick={() => cupHit(3, 2)}
        />
        <span
          className={"dot" + (props.cupHits.row[3].column[3] ? " hitted" : "")}
          onClick={() => cupHit(3, 3)}
        />
      </div>
      <div>
        <span
          className={"dot" + (props.cupHits.row[4].column[1] ? " hitted" : "")}
          onClick={() => cupHit(4, 1)}
        />
        <span
          className={"dot" + (props.cupHits.row[4].column[2] ? " hitted" : "")}
          onClick={() => cupHit(4, 2)}
        />
        <span
          className={"dot" + (props.cupHits.row[4].column[3] ? " hitted" : "")}
          onClick={() => cupHit(4, 3)}
        />
        <span
          className={"dot" + (props.cupHits.row[4].column[4] ? " hitted" : "")}
          onClick={() => cupHit(4, 4)}
        />
      </div>
    </div>
  );
};

export default CupTriangle;

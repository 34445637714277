import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../css/navbar.css";

import * as ROUTES from "../../constants/routes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navigation = () => {
  let location = useLocation();

  return (
    <div className="topnav" id="myTopnav">
      <Link
        className={location.pathname === ROUTES.HOME ? "active" : ""}
        to={ROUTES.HOME}
      >
        <b>CyberPong</b>
      </Link>
      <Link
        className={location.pathname === ROUTES.ADMIN ? "active" : ""}
        to={ROUTES.ADMIN}
      >
        Admin
      </Link>
      <Link
        className={location.pathname === ROUTES.PLAYER ? "active" : ""}
        to={ROUTES.PLAYER}
      >
        Player
      </Link>
      <Link
        className={location.pathname === ROUTES.SECONDSCREEN ? "active" : ""}
        to={ROUTES.SECONDSCREEN}
      >
        Second Screen
      </Link>
      

      <span
        className="icon"
        onClick={function myFunction() {
          var x = document.getElementById("myTopnav");
          if (!x) return;
          if (x.className === "topnav") {
            x.className += " responsive";
          } else {
            x.className = "topnav";
          }
        }}
      >
        <FontAwesomeIcon icon={faBars} />
      </span>
    </div>
  );
};

export default Navigation;

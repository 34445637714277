import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from "../Home";
import Navigation from "../Navigation";
import LandingPage from "../LandingPage";
import Admin from "../Admin";
import Player from "../Player";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import SecondScreen from "../SecondScreen";

import * as ROUTES from "../../constants/routes";

const App = () => (
  <Router>
    <div>
      <Navigation />
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.SIGNIN} component={SignIn} />
      <Route path={ROUTES.SIGNUP} component={SignUp} />
      <Route path={ROUTES.HOME} component={Home} />
      <Route path={ROUTES.ADMIN} component={Admin} />
      <Route path={ROUTES.PLAYER} component={Player} />
      <Route path={ROUTES.SECONDSCREEN} component={SecondScreen} />
    </div>
  </Router>
);

export default App;

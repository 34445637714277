import React from "react";
import Jitsi from "react-jitsi";

const VideoCall = (props: { name: string }) => {
  const config: any = {
    prejoinPageEnabled: false,
    startWithAudioMuted: true,
  };

  const interfaceConfig = {
    APP_NAME: "Beerpong",
    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
    HIDE_INVITE_MORE_HEADER: true,
    MOBILE_APP_PROMO: false,
    SHOW_CHROME_EXTENSION_BANNER: false,
    TOOLBAR_BUTTONS: [
      "microphone",
      "camera",
      "fullscreen",
      "chat",
      /* "profile", */
      "info",
      "settings",
      "videoquality",
      "filmstrip",
      "tileview",
      "help",
      "closedcaptions",
      /* "sharedvideo", */
      /* "invite", */
    ],
    SETTINGS_SECTIONS: ["devices", "language"],
  };

  return (
    <div>
      <Jitsi
        domain="meet.jit.si"
        roomName={"thisIsASecretTest12346876hwfjkghjk"}
        displayName={props.name}
        interfaceConfig={interfaceConfig as any}
        config={config}
        /* containerStyle={{ width: "1200px", height: "800px" }} */
      />
    </div>
  );
};

export default VideoCall;

import React from "react";

const LandingPage = () => {
  return (
    <div>
      <h1>Beates Beerpong</h1>
      <p>Willkommen beim Beerpong für zuhause </p>
      <p>
        Labore duis deserunt sint eu. Officia pariatur sit ullamco tempor
        consectetur aliquip magna nostrud ex Lorem anim quis laboris. Labore
        ipsum ut laboris excepteur Lorem minim commodo duis anim sunt laborum.
        Mollit veniam velit eiusmod consectetur esse in non elit esse magna ea.
        Reprehenderit pariatur aute adipisicing sit magna. Pariatur adipisicing
        non velit eiusmod. Commodo dolor do nostrud et. Fugiat deserunt elit
        fugiat cupidatat duis ea amet mollit ad qui duis culpa. Adipisicing sint
        labore ad veniam laborum aliqua adipisicing in pariatur adipisicing ex
        nulla est ex. Do reprehenderit laboris Lorem non est id minim
        consectetur officia est. Anim culpa irure nisi Lorem est ullamco anim
        elit velit incididunt amet. Id aute eu adipisicing laboris sit consequat
        dolore esse quis eiusmod ea dolor excepteur. Ipsum est adipisicing
        ullamco ut deserunt adipisicing eiusmod ad cupidatat dolor. Culpa aliqua
        aute laboris sint proident enim excepteur occaecat pariatur veniam et
        sunt. Fugiat cillum pariatur qui aute do Lorem. Occaecat nulla laboris
        eiusmod voluptate minim qui. Commodo mollit commodo sint eiusmod velit
        quis exercitation labore Lorem enim cillum ad sit. Id Lorem anim ea sunt
        labore nostrud est cupidatat fugiat laborum cupidatat nostrud minim.
        Aute enim deserunt anim id consequat est deserunt anim velit Lorem
        excepteur. Cillum tempor dolor laboris ullamco occaecat ea laboris.
      </p>
      <p>
        Aliquip duis cupidatat nostrud esse. Eu velit Lorem laborum duis
        adipisicing velit. Labore qui dolor tempor sit excepteur ipsum laboris.
        Amet sunt amet consectetur amet incididunt est et duis dolor aliqua sit
        sint tempor. Cillum enim duis cupidatat officia incididunt ea culpa sint
        cillum non eu culpa amet. Eu aute nostrud nisi non fugiat. Velit
        excepteur excepteur aliqua sunt eiusmod labore labore deserunt. Aliquip
        ea exercitation Lorem do eu nostrud commodo sunt exercitation. In
        adipisicing amet sit laborum id id et aliqua. Proident magna
        exercitation aliqua officia fugiat aliquip labore minim ullamco.
        Pariatur mollit in esse commodo cupidatat et ad id. Est dolor esse
        ullamco pariatur magna occaecat aute velit. Laboris incididunt qui
        exercitation laborum. Pariatur in officia magna aliquip dolore tempor.
        Quis aliqua esse ad magna non velit veniam eu sunt proident nostrud elit
        cillum ad. Culpa eiusmod nulla deserunt adipisicing reprehenderit nulla
        consequat pariatur ipsum aliquip consequat irure culpa. Consequat ipsum
        pariatur dolor dolor qui cillum cillum laboris elit est qui dolore.
        Cillum est commodo duis cupidatat aliqua ullamco. Dolore id nostrud
        irure irure adipisicing velit nisi tempor do sunt cillum dolore mollit.
        Aliquip voluptate deserunt incididunt ut excepteur mollit ut. Veniam
        tempor fugiat occaecat ad eu consequat sint voluptate occaecat
        reprehenderit qui incididunt nulla magna. Ad irure dolore proident
        fugiat et anim duis sunt minim. Consectetur esse aliquip proident eu.
        Elit quis id nisi officia. Ad elit consequat pariatur occaecat dolore
        nostrud ex id et occaecat aliqua ipsum. Id anim aliquip dolore et
        aliquip qui veniam magna id ipsum. Est voluptate deserunt dolor anim
        esse culpa sunt. Reprehenderit dolore culpa qui ad mollit adipisicing
        ullamco consequat cupidatat exercitation. Quis officia anim mollit
        velit. Duis labore dolor aliqua in labore non nulla et sit aute duis eu
        cupidatat. Aliquip labore ullamco magna aliqua excepteur fugiat
        incididunt elit ad. Do occaecat eiusmod irure pariatur dolor laborum
        labore amet anim quis pariatur. Lorem velit dolor esse proident aliqua
        ex nulla qui exercitation esse quis. Esse dolore aute in proident in.
      </p>
    </div>
  );
};

export default LandingPage;
